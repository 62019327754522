/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/navigation";
import { ChevronRight, MessageSquareText } from "lucide-react";

import AppButton from "../buttons/AppButton";
import AppText from "../AppText";
import { AppIconButton } from "../buttons/AppIconButton";
import CustomLink from "../CustomLink";
import { cn } from "@/lib/utils";
import { Call20, List20 } from "@/assets/Icons";
import { useSaleUnitType } from "@/store/clientStore";
import { BODY_SMALL_EMPHASIS, BUTTON_MEDIUM } from "@/styles";
import { Locale } from "@/i18n.config";
import Image from "next/image";

const UNIT_TYPE_MAPPING: { [key: string]: string } = {
  "/sales-showroom/search?unit-type=Office+Trailer": "Office Trailer",
  "/sales-showroom/search?unit-type=Classroom": "Classroom",
  "/sales-showroom/search?unit-type=Sales+Office": "Sales Office",
  "/sales-showroom/search?unit-type=Storage+Container": "Storage Container",
};

const NavPanelWithLeftMenu = ({ tabs, lang }: { tabs: any; lang: Locale }) => {
  const [activeBlock, setActiveBlock] = useState(0);
  const router = useRouter();
  const cardsPanelRef = useRef<any>();

  const handleOpenTabLink = (link: string) => {
    router.push(`/${lang}${link}`);
  };

  const handleMenuKeyDown = (e: any, link: string) => {
    if (e.key === "ArrowRight") {
      if (cardsPanelRef.current) {
        const firstCard = cardsPanelRef.current.querySelector("a") as HTMLElement;
        if (firstCard) {
          firstCard.focus();
        }
      }
    } else if (e.key === "Enter") {
      handleOpenTabLink(link);
    }
  };
  const handlePanelLeftArrow = (e: any) => {
    if (e.key === "ArrowLeft") {
      const activeTab = document.querySelector("[data-tabactive='true']") as HTMLElement;
      if (activeTab) {
        activeTab.focus();
      }
    }
  };

  return (
    <div className="flex">
      <div className="w-full max-w-[266px] bg-royal-blue-10">
        <ul>
          {tabs.map((tab: any, idx: number) => (
            <li
              key={tab.id}
              autoFocus={true}
              onKeyDown={(e) => handleMenuKeyDown(e, tab.tabCtaLink)}
              onFocus={() => setActiveBlock(idx)}
              onMouseEnter={() => setActiveBlock(idx)}
              className={cn(
                "px-6 py-4 gap-2 cursor-pointer flex flex-col",
                activeBlock === idx ? "bg-white" : "bg-royal-blue-10  "
              )}
            >
              <div className=" flex items-center justify-between gap-4">
                <AppButton
                  data-tabactive={activeBlock === idx}
                  intent="ghost"
                  onMouseDown={() => handleOpenTabLink(tab.tabCtaLink)}
                  title={tab.tabLabel}
                  label={tab.tabLabel}
                  className={cn(BUTTON_MEDIUM)}
                />
                {activeBlock !== idx && <ChevronRight size={20} aria-label="chevron right icon" />}
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex flex-col w-full p-6 pl-8" onKeyDown={handlePanelLeftArrow}>
        {/* <div className="animate-in fade-in duration-200" key={activeBlock}> */}
        <div className="flex items-center justify-between mb-6">
          <div>
            <AppText type={"SUB_HEADING_MEDIUM"} className={"text-royal-blue  pl-1"}>
              {tabs[activeBlock].tabHeading}
            </AppText>
            <AppText type={"BODY_SMALL"} className={" pl-1"}>
              {tabs[activeBlock].tabSubheading}
            </AppText>
          </div>

          <AppIconButton
            intent="ghost"
            label={tabs[activeBlock].tabCtaLabel}
            icon={<ChevronRight size={20} aria-label="chevron right icon" />}
            iconOnRightSide
            className="pt-0 pb-1 pr-0"
            link={tabs[activeBlock].tabCtaLink}
          />
        </div>

        {/* {kind === "Products & Solutions" && ( */}
        {tabs && tabs[0] && tabs[0].featuredCard && (
          <div className="flex gap-6" ref={cardsPanelRef}>
            <div
              className={cn(
                "grid grid-cols-12 gap-x-3 gap-y-2 py-4 w-full h-fit",
                tabs[activeBlock].featuredCard || tabs[activeBlock].secondaryFeaturedCard ? "max-w-[787px]" : ""
              )}
            >
              {tabs[activeBlock].cards.map(
                (card: { image: string; ctaLabel: string; placeholder: string; ctaLink: string; id: string }) => {
                  return (
                    <CustomLink
                      href={card.ctaLink}
                      key={card.id}
                      className={cn(
                        "group ring-offset-1 overflow-hidden bg-white",
                        tabs[activeBlock].featuredCard || tabs[activeBlock].secondaryFeaturedCard
                          ? "xl:col-span-4 col-span-6"
                          : "xl:col-span-3 col-span-4"
                      )}
                    >
                      <div className="overflow-hidden aspect-[16/9] relative">
                        <Image
                          className="group-hover:scale-105 object-cover aspect-[16/9] w-full transition"
                          fill
                          placeholder="blur"
                          blurDataURL={card.placeholder}
                          sizes="300px"
                          src={card.image}
                          alt={card.ctaLabel}
                        />
                      </div>

                      <AppButton
                        tabIndex={-1}
                        intent="ghost"
                        title={card.ctaLabel}
                        label={card.ctaLabel}
                        className={cn(BODY_SMALL_EMPHASIS, "text-left whitespace-normal")}
                      />
                    </CustomLink>
                  );
                }
              )}
            </div>

            {/** right cards */}
            {(tabs[activeBlock].featuredCard || tabs[activeBlock].secondaryFeaturedCard) && (
              <div className="py-4 max-w-[213px] w-full flex flex-col gap-4">
                {tabs[activeBlock].featuredCard && (
                  <CustomLink
                    href={tabs[activeBlock].featuredCard.ctaLink}
                    className="group overflow-hidden bg-white cursor-pointer"
                  >
                    <div className="overflow-hidden bg-[#005EB8] relative aspect-[16/9]">
                      <Image
                        className="group-hover:scale-105 object-cover w-full max-w-[280px] aspect-[16/9] max-h-[154px] transition"
                        fill
                        placeholder="blur"
                        blurDataURL={tabs[activeBlock].featuredCard.placeholder}
                        sizes="100vw"
                        src={tabs[activeBlock].featuredCard.image}
                        alt={tabs[activeBlock].featuredCard.ctaLabel}
                      />
                    </div>

                    <div className="p-4 pl-3 bg-[#F2F2F2]">
                      <AppIconButton
                        tabIndex={-1}
                        intent="ghost"
                        label={tabs[activeBlock].featuredCard.ctaLabel}
                        icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                        iconOnRightSide
                        className={cn(BODY_SMALL_EMPHASIS, "pt-0 pb-1 pr-0")}
                      />

                      <AppText type={"BODY_SMALL"} className={"text-black-80 not-italic  pl-1 line-clamp-2"}>
                        {tabs[activeBlock].featuredCard.subheading}
                      </AppText>
                    </div>
                  </CustomLink>
                )}
                {tabs[activeBlock].secondaryFeaturedCard && (
                  <CustomLink
                    href={tabs[activeBlock].secondaryFeaturedCard.ctaLink}
                    className="group overflow-hidden bg-white cursor-pointer"
                  >
                    <div className="overflow-hidden bg-[#005EB8] relative aspect-[16/9]">
                      <Image
                        className="group-hover:scale-105 object-cover w-full max-w-[280px]  aspect-[16/9] max-h-[154px] transition"
                        fill
                        placeholder="blur"
                        blurDataURL={tabs[activeBlock].secondaryFeaturedCard.placeholder}
                        sizes="300px"
                        src={tabs[activeBlock].secondaryFeaturedCard.image}
                        alt={tabs[activeBlock].secondaryFeaturedCard.ctaLabel}
                      />
                    </div>

                    <div className="p-4 pl-3 bg-[#F2F2F2]">
                      <AppIconButton
                        tabIndex={-1}
                        intent="ghost"
                        label={tabs[activeBlock].secondaryFeaturedCard.ctaLabel}
                        icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                        iconOnRightSide
                        className={cn(BODY_SMALL_EMPHASIS, "pt-0 pb-1 pr-0")}
                      />

                      <AppText type={"BODY_SMALL"} className={"text-black-80 not-italic  pl-1 line-clamp-2"}>
                        {tabs[activeBlock].secondaryFeaturedCard.subheading}
                      </AppText>
                    </div>
                  </CustomLink>
                )}
              </div>
            )}
          </div>
        )}

        {/* {kind === "Resources & Support" && ( */}
        {tabs && tabs[0] && !tabs[0].featuredCard && (
          <div className="2xl:gap-6 grid grid-cols-12 gap-4" ref={cardsPanelRef}>
            {/** contact us panel */}
            {activeBlock === 4
              ? tabs[activeBlock].cards.map(
                  (
                    card: { image: string; ctaLabel: string; ctaLink: string; id: string; placeholder: string },
                    idx: number
                  ) => {
                    const isChatItem = card.ctaLink === "[chat]";

                    const locationLink = isChatItem ? "-" : card.ctaLink;
                    const onClickHandler = isChatItem
                      ? (e: any) => {
                          e.preventDefault();
                          // Click div with id "liveagent_button_online_5738c000000spv5" to start chat // It will cause a popup
                          // window?.liveagent?.startChat(process.env.NEXT_PUBLIC_CHAT_ID ?? "5738c000000spv5"); // It will cause a popup
                          // This hack will open the chat in the tab
                          console.log(`Opening: Chat`);
                          const button = document.querySelector(".helpButtonEnabled") as HTMLButtonElement;
                          button?.click();
                        }
                      : undefined;

                    return (
                      <CustomLink
                        key={card.id}
                        href={locationLink || ""}
                        className="group ring-offset-1 xl:col-span-3 col-span-4 overflow-hidden bg-white"
                        onClick={onClickHandler}
                      >
                        <div className="overflow-hidden relative  aspect-[16/9]">
                          <Image
                            className="group-hover:scale-105 object-cover w-full  aspect-[16/9] max-h-[154px] transition"
                            fill
                            placeholder="blur"
                            blurDataURL={card.placeholder}
                            sizes="300px"
                            src={card.image}
                            alt={card.ctaLabel}
                          />
                        </div>

                        <div>
                          <AppIconButton
                            tabIndex={-1}
                            intent="ghost"
                            label={card.ctaLabel}
                            icon={idx === 0 ? <MessageSquareText size={16} /> : idx === 1 ? <Call20 /> : <List20 />}
                            iconOnRightSide
                            className={cn(BODY_SMALL_EMPHASIS, "justify-between w-full")}
                          />
                        </div>
                      </CustomLink>
                    );
                  }
                )
              : (tabs[activeBlock].cards || []).map(
                  (card: { ctaLabel: string; ctaLink: string; image: string; id: string; placeholder: string }) => (
                    <ResourceCard
                      key={card.id}
                      imgSrc={card.image}
                      link={card.ctaLink}
                      placeholder={card.placeholder}
                      label={card.ctaLabel}
                    />
                  )
                )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavPanelWithLeftMenu;

export const ResourceCard = ({
  link,
  label,
  imgSrc,
  placeholder,
}: {
  link: string;
  label: string;
  placeholder: string;
  imgSrc: string;
}) => {
  const { setUnitTypeStore } = useSaleUnitType();

  const [clickTarget, setClickTarget] = useState<string | null>(null);

  useEffect(() => {
    if (link && UNIT_TYPE_MAPPING[link]) {
      setClickTarget(UNIT_TYPE_MAPPING[link]);
    }
  }, [link]);

  return (
    <CustomLink
      href={link}
      className="group ring-offset-1 xl:col-span-3 col-span-4 overflow-hidden bg-white"
      onClick={() => (clickTarget ? setUnitTypeStore(clickTarget) : {})}
    >
      <div className="relative overflow-hidden aspect-[16/9]">
        <Image
          className="group-hover:scale-105 object-cover aspect-[16/9] w-full transition"
          fill
          placeholder="blur"
          blurDataURL={placeholder}
          sizes="300px"
          src={imgSrc}
          alt={label}
        />
      </div>

      <div>
        <AppButton tabIndex={-1} intent="ghost" title={label} label={label} className="text-left whitespace-normal" />
      </div>
    </CustomLink>
  );
};
