import React from "react";
import AppButton from "./AppButton";
import { cn } from "@/lib/utils";

/**
 * Props for the CtaVersion component.
 * @interface CtaVersionProps
 */
interface CtaVersionProps {
  /** Background color class for the button */
  background?: string;
  /** Label text for the CTA button */
  ctaLabel: string;
  /** URL or path for the CTA button link */
  ctaLink: string;
  /** Background color class for the button on hover */
  hoverBackground?: string;
  /** Visual style intent for the button */
  intent?: "primary" | "secondary" | "ghost";
  /** Text color class for the button */
  textColor?: string;
  /** Additional CSS classes to apply to the button */
  className?: string;
}

/**
 * CtaVersion component for rendering a customizable CTA button.
 *
 * @param {CtaVersionProps} props - The props for the CtaVersion component
 * @returns {React.ReactElement} A rendered CTA button
 */
const CtaVersion: React.FC<CtaVersionProps> = ({
  background,
  ctaLabel,
  ctaLink,
  hoverBackground,
  intent,
  textColor,
  className,
}) => {
  const buttonClasses = cn(
    textColor && `text-${textColor}`,
    background && `bg-${background}`,
    hoverBackground && `hover:bg-${hoverBackground}`,
    className
  );

  return <AppButton label={ctaLabel} link={ctaLink} intent={intent} className={buttonClasses} />;
};

export default CtaVersion;
