"use client";

import { useRouter } from "next/navigation";
import React, { FormEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { ArrowRight, Search } from "lucide-react";

import { SEARCH_QUICK_LINKS } from "../constants";
import { AppButton } from "../buttons/AppButton";

interface INavSearchPanel {
  lang: string;
  dict: { [key: string]: string };
}

const NavSearchPanel = ({ lang, dict }: INavSearchPanel) => {
  const [search, setSearch] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;

    setSearch(newValue || "");
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && search && search !== "") {
      e.preventDefault();
      openResults();
    }
  };

  const openResults = () => {
    const url = encodeURI(`/${lang}/search?query=${search}`);
    router.push(url);
  };

  const handleSearchArrow = () => {
    if (!search || search === "") return;

    openResults();
  };

  const handleSuggestionClick = (value: string) => {
    const url = encodeURI(`/${lang}/search?query=${value}`);
    router.push(url);
  };

  return (
    <div className="flex flex-col px-8 py-6">
      <div className="relative flex items-center justify-end w-full">
        <Search size={20} className="" />

        <input
          ref={inputRef}
          placeholder={dict.searchBarPlaceholder}
          value={search}
          onChange={handleChange}
          type="search"
          onKeyDown={handleKeyDown}
          className="w-full h-[28px] my-2 outline-none pl-2 font-[500] font-saira tracking-[-0.1px] leading-[1.75] text-16"
        />

        <button
          id="button-nav-search"
          onClick={handleSearchArrow}
          className="ring-transparent ring-1 ring-offset-2 focus:ring-dijon ml-2 outline-none"
        >
          <ArrowRight size={20} aria-label="arrow right icon" />
        </button>
      </div>

      <hr className=" text-black-60 h-[1px]" />

      {/* <AppText type="LABEL_LARGE" className="text-royal-blue mt-8">
        {dict.quickLinks}
      </AppText> */}

      <div className="flex flex-col gap-2 mt-8">
        {SEARCH_QUICK_LINKS.map((elem, idx) => (
          <AppButton
            onClick={() => handleSuggestionClick(elem.label)}
            key={idx}
            intent="ghost"
            title={elem.label}
            label={elem.label}
            className="py-0 pb-[6px]"
          />
        ))}
      </div>
    </div>
  );
};

export default NavSearchPanel;
